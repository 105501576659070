/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {useIntl} from 'react-intl'
import ReCAPTCHA from "react-google-recaptcha";
import { useEffectOnce } from 'react-use'
import { KEY } from '../../../shared/constants/shared-contants'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'superadmin@localhost.com',
  password: 'Administrator1!',
}
interface IProps {
  handleLogin:(token: string, email: string, twoFactorEnabled: boolean | null, twoFactorConnected: boolean) => void;
}

const recaptchaRef = React.createRef<ReCAPTCHA>();

const Login: React.FC<IProps> = ({ handleLogin }) => {
  const [loading, setLoading] = useState(false)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password, captchaToken)
          .then(({ data: { token, twoFactorEnabled, twoFactorConnected } }) => {
            handleLogin(token, values.email, twoFactorEnabled, twoFactorConnected);
          })
          .catch(({ response: { data: { message } } }) => {
            recaptchaRef.current?.reset();
            setCaptchaToken(null);
            setSubmitting(false)
            setStatus(message)
          })
          .finally(() => {
            setLoading(false)
          });
      }, 1000)
    },
  })

  const onReCAPTCHAChange = async (captchaToken: string | null) => {
    if (captchaToken) {
      setCaptchaToken(captchaToken);
    }
  };

  const handleFormKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === KEY.enter) {
      event.preventDefault();
      if (formik.isValid && !formik.isSubmitting) {
        formik.handleSubmit();
      }
    }
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      onKeyDown={handleFormKeyDown}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Welcome to {intl.formatMessage({id: 'APP.NAME'})}</h1>
      </div>
      {formik.status ? (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center align-items-center mb-5">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcODrgpAAAAADg3cCPtrUHNltj3NjDTMl74sJXb"
          onChange={onReCAPTCHAChange}
        />
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link
          to='/auth/forgot-password'
          className='link-primary fs-6 fw-bolder'
          style={{ marginLeft: '5px' }}
        >
          Forgot Password ?
        </Link>
      </div>
    </form>
  )
}

export {Login}
