import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePageData } from '../../_metronic/layout/core';
import { PAGE_PERMISSION } from '../modules/common/constant/roles-constant';

const ActivePageBasedRedirect = () => {

  const { currentUser: { activePages } } = usePageData();

  if (!activePages) {
    return null;
  }

  const permissionsOrder = [
    { permission: PAGE_PERMISSION.Dashboard, route: '/dashboard' },
    { permission: PAGE_PERMISSION.FloatAndCash, route: '/float-and-cash' },
    { permission: PAGE_PERMISSION.Sales, route: '/sales' },
    { permission: PAGE_PERMISSION.Quotes, route: '/quotes' },
    { permission: PAGE_PERMISSION.PriceList, route: '/price-list' },
    { permission: PAGE_PERMISSION.Suppliers, route: '/suppliers' },
    { permission: PAGE_PERMISSION.Products, route: '/products' },
    { permission: PAGE_PERMISSION.Reports, route: '/reports' },
    { permission: PAGE_PERMISSION.Admin_RolesAndPermissions, route: '/role' },
    { permission: PAGE_PERMISSION.Admin_Audit, route: '/audit' },
    { permission: PAGE_PERMISSION.Admin_UserManagement, route: '/user-management' },
    { permission: PAGE_PERMISSION.Admin_Settings, route: '/settings' },
    { permission: PAGE_PERMISSION.Admin_MYOB, route: '/myob' },
  ];

  for (let { permission, route } of permissionsOrder) {
    if (activePages.some(page => page.name === permission)) {
      return <Redirect to={route} />;
    }
  }

  return null;

};

export default ActivePageBasedRedirect;
