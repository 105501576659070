import React, { useState, useRef, useContext, useEffect, useCallback } from 'react'
import {useDispatch} from 'react-redux'
import * as auth from '../redux/AuthRedux'
import {login2Fa} from '../redux/AuthCRUD'
import { AuthContext } from '../context/AuthContext'
import { useHistory } from 'react-router-dom'
import { KEY } from '../../../shared/constants/shared-contants'

interface IProps {
  cancelHandler: () => void
}

const Login2Fa: React.FC<IProps> = ({cancelHandler}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [status, setStatus] = useState('');
  const history = useHistory();
  const { email } = useContext(AuthContext);

  useEffect(() => {
    if (!email) {
      history.push('/auth/login');
    }
  }, [history, email])

  const handleSubmit = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      const code = codeDigits.join('');
      login2Fa(email, code)
        .then(({ data: { token, twoFactorEnabled } }) => {
          setLoading(false);
          dispatch(auth.actions.login(token));
        })
        .catch((err) => {
          setLoading(false);
          setStatus('The verification code is incorrect');
        });
    }, 1000);
  }, [email, codeDigits]);

  const handleDigitInput = (value: string, index: number) => {
    const newCodeDigits = [...codeDigits];
    newCodeDigits[index] = value;
    setCodeDigits(newCodeDigits);

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleDigitKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEY.enter) {
      event.preventDefault();
      handleSubmit();
    }
  }

  return (
    <>
      {status ? (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      ) : (
        <></>
      )}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Security Verification</h1>
        <h6 className='mb-3'>Authenticator</h6>
        <span> Please enter the generated code from your Authenticator Application</span>
      </div>
      <div className="d-flex justify-content-center mb-10">
        {codeDigits.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            onKeyDown={handleDigitKeyDown}
            onChange={e => handleDigitInput(e.target.value, index)}
            className="form-control mx-1 text-center"
            style={{ width: '3rem', fontSize: '1rem' }}
            ref={el => inputRefs.current[index] = el}
          />
        ))}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={loading}
          onClick={handleSubmit}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button type='button' className='btn btn-outline-secondary w-100 ' onClick={cancelHandler}>
          Cancel
        </button>
      </div>
    </>
  )
}

export {Login2Fa}
