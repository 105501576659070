import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {MenuTestPage} from '../pages/MenuTestPage'
import ActivePageBasedRedirect from './ActivePageBasedRedirect'
import { PAGE_PERMISSION } from '../modules/common/constant/roles-constant'
import { Page } from '../modules/roles/models/role-model'
import ActivatedPageRoute from './ActivatedPageRoute'

type Props = {
  activePages: Page[]
};

const PrivateRoutes: React.FC<Props> = ({ activePages = [] }) => {

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const RolePage = lazy<React.FC>(() => import('../modules/roles/RolePage'))
  const FeaturePage = lazy<React.FC>(() => import('../modules/features/FeaturePage'))

  const SalesPage = lazy(() => import('../modules/pages/sales/SalesPage'))
  const QuotesPage = lazy(() => import('../modules/pages/quotes/QuotesPage'))
  const FloatAndCashPage = lazy(() => import('../modules/pages/float-and-cash/FloatAndCashPage'))
  const PriceListPage = lazy(() => import('../modules/pages/price-list/PriceListPage'))
  const SuppliersPage = lazy(() => import('../modules/pages/suppliers/SuppliersPage'))
  const ProductsPage = lazy(() => import('../modules/pages/products/ProductsPage'))
  const ReportsPage = lazy(() => import('../modules/pages/reports/ReportsPage'))
  const UserManagementPage = lazy(() => import('../modules/pages/user-management/UserManagementPage'))
  const SettingsPage = lazy(() => import('../modules/pages/settings/SettingsPage'))
  const MyobPage = lazy(() => import('../modules/pages/myob/MyobPage'))
  const AuditPage = lazy(() => import('../modules/pages/audit/AuditPage'))
  const DashboardPage = lazy(() => import('../modules/pages/dashboard/DashboardPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>

        <Route path='/feature' render={() => <FeaturePage />} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />

        <ActivatedPageRoute
          path='/dashboard'
          component={DashboardPage}
          permission={PAGE_PERMISSION.Dashboard}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/role'
          component={RolePage}
          permission={PAGE_PERMISSION.Admin_RolesAndPermissions}
          activePages={activePages} />

        <ActivatedPageRoute
          path='/sales'
          component={SalesPage}
          permission={PAGE_PERMISSION.Sales}
          activePages={ activePages }
        />

        <ActivatedPageRoute
          path='/quotes'
          component={QuotesPage}
          permission={PAGE_PERMISSION.Quotes}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/float-and-cash'
          component={FloatAndCashPage}
          permission={PAGE_PERMISSION.FloatAndCash}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/price-list'
          component={PriceListPage}
          permission={PAGE_PERMISSION.PriceList}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/suppliers'
          component={SuppliersPage}
          permission={PAGE_PERMISSION.Suppliers}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/products'
          component={ProductsPage}
          permission={PAGE_PERMISSION.Products}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/reports'
          component={ReportsPage}
          permission={PAGE_PERMISSION.Reports}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/user-management'
          component={UserManagementPage}
          permission={PAGE_PERMISSION.Admin_UserManagement}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/settings'
          component={SettingsPage}
          permission={PAGE_PERMISSION.Admin_Settings}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/myob'
          component={MyobPage}
          permission={PAGE_PERMISSION.Admin_MYOB}
          activePages={activePages}
        />

        <ActivatedPageRoute
          path='/audit'
          component={AuditPage}
          permission={PAGE_PERMISSION.Admin_Audit}
          activePages={activePages}
        />

        <Route path='/auth' component={ActivePageBasedRedirect} />
        <Route exact path='/' component={ActivePageBasedRedirect} />

        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

export { PrivateRoutes };