import { SortDescriptor } from '@progress/kendo-data-query';
import axios from 'axios';
import { SupplierDetailsFormData, SupplierFilter } from '../models/supplier-models';
import { redirectToMyobLogin } from '../../../../../Utils';
import { PriceListFilter } from '../../price-list/models/price-list-models';
import { PriceOverrideFilter } from '../models/supplier-models';

export const SUPPLIER_URL = `${process.env.REACT_APP_API_URL}/Supplier`
export const SUPPLIER_PRICE_OVERRIDE_URL = `${process.env.REACT_APP_API_URL}/SupplierPriceOverride`;
export const ACCOUNTING_URL = `${process.env.REACT_APP_API_URL}/Accounting`
export const SCRAPIT_URL = `${process.env.REACT_APP_API_URL}/ScrapIt`
export const MYOB_URL = `${process.env.REACT_APP_API_URL}/Myob`
export const SUPPLIER_PRICE_LIST_URL = `${process.env.REACT_APP_API_URL}/SupplierPriceList`
export const PRICE_LIST_URL = `${process.env.REACT_APP_API_URL}/PriceList`

const getDateOffset = (date: Date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - (offset * 60 * 1000));

  return date;
}

export const getPriceListPdfFileName = async (priceListId: number, supplierId: number, targetDate: Date | null) => {
  if (targetDate) {
    targetDate = getDateOffset(targetDate);
  }
  return await axios.post(`${PRICE_LIST_URL}/pdf-file-name`, {
    priceListId,
    supplierId,
    targetDate
  });
}

export const deletePriceOverride = async (id: number) => {
  return await axios.delete(`${SUPPLIER_PRICE_OVERRIDE_URL}/${id}`);
}

export const getDefaultPriceList = async (
  supplierId: number,
) => {
  return await axios.post(`${SUPPLIER_PRICE_OVERRIDE_URL}/getDefaultPriceList`, {
    supplierId
  });
};

export const createOrUpdatePriceOverride = async (payload: any) => {
  return await axios.post(`${SUPPLIER_PRICE_OVERRIDE_URL}/price-override`, payload);
};

export const getProductOptions = async (
  supplierId: number,
  supplierPriceListId: number
) => {
  return await axios.post(`${SUPPLIER_PRICE_OVERRIDE_URL}/getProducts`, {
    supplierId,
    supplierPriceListId
  });
};

export const sendPriceList = async (
  emails: string[],
  date: Date,
  supplierPriceListId: number
) => {
  return await axios.post(`${SUPPLIER_PRICE_LIST_URL}/send-pricelist`, {
    emails,
    date,
    supplierPriceListId
  });
};

export const createSupplierPriceListDetail = async (payload: any) => {
  return await axios.post(`${SUPPLIER_PRICE_LIST_URL}`, payload);
}

export const getSupplierPriceLists = async (supplierId: number | null) => {
  return await axios.get(`${SUPPLIER_PRICE_LIST_URL}/${supplierId}`);
}

export const getSupplierPriceListDetails = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter: PriceListFilter
) => {

  if (filter.date) {
    const offset = filter.date.getTimezoneOffset();
    filter.date = new Date(filter.date.getTime() - (offset * 60 * 1000));
  }

  return await axios.post(`${SUPPLIER_PRICE_LIST_URL}/table`, {
    skip,
    take,
    sort: (pageSort && pageSort.field && pageSort.dir) ? `${pageSort.field} ${pageSort.dir}` : '',
    filter
  });
}

export const getSuppliersData = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter: SupplierFilter
) => {
  return await axios.post(`${SUPPLIER_URL}/table`, {
    skip,
    take,
    sort: (pageSort && pageSort.field && pageSort.dir) ? `${pageSort.field} ${pageSort.dir}` : '',
    filter
  });
}

export const getSupplierDetails = async (supplierId: number) => {
  return await axios.get(`${SUPPLIER_URL}/details/${supplierId}`);
}

export const saveSupplierDetails = async (payload: SupplierDetailsFormData) => {
  return await axios.put(`${SUPPLIER_URL}/details`, payload);
}

export const getSupplierPriceOverrides = async (
  skip: number,
  take: number,
  pageSort: SortDescriptor,
  filter: PriceOverrideFilter
) => {
  return await axios.post(`${SUPPLIER_PRICE_OVERRIDE_URL}/table`, {
    skip,
    take,
    sort: (pageSort && pageSort.field && pageSort.dir) ? `${pageSort.field} ${pageSort.dir}` : '',
    filter
  });
}

export const fetchSuppliers = async () => {
  const response = await axios.get(`${ACCOUNTING_URL}/suppliers`)
  if (response.data.status === 'redirect') {
    redirectToMyobLogin(response.data.clientId)
  }
  return response
}

export const updateMyobSupplierData = async (id: number, name: string, abn: string, uid: string) => {
  return await axios.patch(`${SUPPLIER_URL}/myob-supplier`, { id, name, abn, uid })
}

export const refreshLocalSuppliers = async () => {
  return await axios.get(`${SCRAPIT_URL}/refreshLocalSuppliers`) 
}

export const fetchMyobTaxCodes = async () => {
  const response = await axios.get(`${ACCOUNTING_URL}/tax-codes`)
  return response
}

export const fetchTimsTaxCodes = async () => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/TaxCode`)
}

export const refreshLocalSuppliersWithMyobData = async () => {
  return await axios.get(`${MYOB_URL}/refreshlocalsupplierswithmyobdata`) 
}

export const deactivateSupplier = async (Uid: string) => {
  return await axios.post(`${SUPPLIER_URL}/deactivate`, {
    Uid,
  });
}

export const checkSupplierName = async (name: string) => {
  const response = await axios.post(`${SUPPLIER_URL}/check-supplier-name`, { name });
  return response.data;
};

export const createSupplier = async (payload: SupplierDetailsFormData, captchaToken: string | null) => {
  return await axios.post(`${SUPPLIER_URL}`, { ...payload, captchaToken });
}