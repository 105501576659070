/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import {Login} from './Login'
import { useDispatch } from 'react-redux';
import * as auth from '../redux/AuthRedux'
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
export function LoginPage() {

  const dispatch = useDispatch()
  const history = useHistory();

  const { setEmail, setToken } = useContext(AuthContext);


  const handleLogin = (token: string, email: string, twoFactorEnabled: boolean | null, twoFactorConnected: boolean) => {
    setEmail(email);
    setToken(token);

    if (twoFactorEnabled === null) {
      dispatch(auth.actions.login(token))
      return;
    }

    if (twoFactorEnabled === true) {
      if (twoFactorConnected === true) {
        history.push('/auth/login-2fa');
        return;
      }
      history.push('/auth/setup-2fa')
      return;
    }

    if (twoFactorEnabled === false) {
      history.push('/auth/option-2fa');
      return;
    }
  }

  return (<Login handleLogin={handleLogin} />);
}