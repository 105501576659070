export enum ROLES {
  SuperAdmin = "SuperAdmin",
  Administrator = "Administrator",
  Vetting = "Vetting",
  FloatManager = "FloatManager"
};

export enum PAGE_PERMISSION {
  Dashboard = "Dashboard",
  DashboardTicketsAuthorise = "Dashboard.Tickets.Authorise",
  DashboardTicketsAuthoriseAll = "Dashboard.Tickets.AuthoriseAll",
  DashboardTicketsApprove = "Dashboard.Tickets.Approve",
  DashboardTicketsSave = "Dashboard.Tickets.Save",
  Sales = "Sales",
  Quotes = "Quotes",
  FloatAndCash = "FloatAndCash",
  PriceList = "PriceList",
  Suppliers = "Suppliers",
  Products = "Products",
  Reports = "Reports",
  Admin_UserManagement = "Administration.UserManagement",
  Admin_RolesAndPermissions = "Administration.RolesAndPermissions",
  Admin_Settings = "Administration.Settings",
  Admin_MYOB = "Administration.MYOB",
  Admin_Audit = "Administration.Audit",
}