import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query'

export type SupplierGridSetup = {
  sort: Array<SortDescriptor>
  skip: number
  take: number
  filter: CompositeFilterDescriptor
}

export type SupplierData = {
  id: number,
  scrapITUID: string,
  scrapITSupplierABN: string,
  scrapITSupplierName: string,
  myobSupplierName: string,
  myobABN: string,
  myobUID: string,
  inEdit: boolean

}

export type SupplierFilter = {
  scrapITUID?: string,
  scrapITSupplierABN?: string,
  scrapITSupplierName?: string,
  myobSupplierName?: string,
  myobabn?: string,
  myobuid?: string,
}

export type SupplierDetails = {
  id: number;
  name: string;
  abn: string;
  status: SupplierStatus;
  type: SupplierType;
  designation: SupplierDesignation;
  scrapITCode: string;
  myobid: string;

  contactName: string;
  supplierSalutation: string;
  phone: string;
  website: string;
  email: string;

  address1: string;
  address2: string;
  suburbOrCity: string;
  postCode: string;
  state: SupplierState;
  country: SupplierCountry;

  taxCode: SupplierTaxCode;
  freightTaxCode: FreightTaxCode;
  taxCodeId: number;
  freightTaxCodeId: number;
  defaultCurrency: DefaultCurrency;
  bsb: string;
  account: string;
  accountName: string;
  remittanceEmail: string;

  supplierId: number;
};

export type SupplierDetailsFormData = SupplierDetails & {
  selectedType?: Option,
  selectedStatus?: Option,
  selectedDesignation?: Option,
  selectedSalutation?: Option
  selectedState?: Option
  selectedCountry?: Option,
  selectedTaxCode?: Option,
  selectedFreightTaxCode?: Option,
  selectedDefaultCurrency?: Option,
  acceptTerms: boolean
}

export type PriceOverrideData = {
  id: number
  startDate: Date,
  endDate: Date,
  productId: number,
  price: number
}

export type PriceOverrideFormData = PriceOverrideData & {
  selectedProduct: Option
}

export type PriceOverrrideGridSetup = {
  sort: Array<SortDescriptor>
  skip: number
  take: number
  filter: CompositeFilterDescriptor
}

export type PriceOverrideFilter = {
  productName?: string,
  price?: number,
}

export type Option = {
  value: number
  label: string
}

export type MyobSupplierData = {
  name: string,
  uid: string,
  abn: string
}

export enum DefaultCurrency {
  AUD = 0
}

export enum FreightTaxCode {
  UseSupplierTaxCode = 0
}

export enum SupplierTaxCode {
  UseSupplierTaxCode = 0
}
export enum SupplierState {
  QLD = 0
}

export enum SupplierCountry {
  Australia = 0
}

export enum SupplierSalutation {
  TaskMaster = 0
}

export enum SupplierDesignation {
  Company = 0
}

export enum SupplierStatus {
  Pending = 0
}

export enum SupplierType {
  Door = 0
}
