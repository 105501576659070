import { jwtDecode } from "jwt-decode";
import * as auth from '../../app/modules/auth/redux/AuthRedux'

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp && decodedToken.exp < currentTime) {
          store.dispatch(auth.actions.logout());
          return new Promise(() => {});
        }
        else {
          config.headers.Authorization = `Bearer ${accessToken}`
        }
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const origRequest = error.config;
      if (error.response && error.response.status === 401) {
        store.dispatch(auth.actions.logout());
      }
      return Promise.reject(error);
    }
  )
}
