import React, { useMemo, useState } from 'react'

interface IProps {
  email: string,
  setEmail: (email: string) => void,
  token: string,
  setToken: (token: string) => void
}

export const AuthContext = React.createContext<IProps>({
  email: '',
  setEmail: (email: string) => { },
  token: '',
  setToken: (token: string) => { },
})

export const AuthContextProvider: React.FC = ({ children }) => {

  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const value: IProps = useMemo(() => {
    return {
      email,
      setEmail,
      token,
      setToken,
    }
  }, [
    email,
    setEmail,
    token,
    setToken
  ])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
