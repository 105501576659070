import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { setupAuthenticator, verify2FaCode } from '../api';
import * as auth from '../redux/AuthRedux'
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { AuthContext } from '../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { KEY } from '../../../shared/constants/shared-contants';

const Setup2Fa: React.FC = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeDigits, setCodeDigits] = useState(Array(6).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { email, token } = useContext(AuthContext);

  useEffect(() => {
    if (!email || !token) {
      history.push('/auth/login');
    }
  }, [history, email, token])

  const handleDigitInput = (value: string, index: number) => {
    const newCodeDigits = [...codeDigits];
    newCodeDigits[index] = value;
    setCodeDigits(newCodeDigits);

    if (value.length === 1 && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleSubmit = useCallback(() => {
    const code = codeDigits.join('');
    setLoading(true);
    verify2FaCode(email, code)
      .then(() => {
        dispatch(auth.actions.login(token));
      })
      .catch(({ response: { data: { message } } }) => {
        setStatus(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [codeDigits, email, token, dispatch]);

  const handleDigitKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEY.enter) {
      event.preventDefault();
      handleSubmit();
    }
  }

  useEffectOnce(() => {
    setupAuthenticator(email).then(({ data: { qrCodeUrl, secret } }) => {
      setQrCodeUrl(qrCodeUrl);
    });
  })

  return (
    <div className='text-center'>
      {status ? (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      ) : (
        <></>
      )}

      <h4 className='mb-5'>Setup 2FA</h4>
      <span>Scan QR code</span>
      {qrCodeUrl && (
        <img
          src={qrCodeUrl}
          alt="QR Code"
          className="mb-10 d-block mx-auto p-2 border border-3 border-secondary rounded"
          style={{ maxWidth: '200px', height: 'auto' }}
        />
      )}
      <div className="mb-3">Enter the code from the Authenticator App</div>
      <div className="d-flex justify-content-center">
        {codeDigits.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            onKeyDown={handleDigitKeyDown}
            onChange={e => handleDigitInput(e.target.value, index)}
            className="form-control mx-1 text-center"
            style={{ width: '3rem', fontSize: '1rem' }}
            ref={el => inputRefs.current[index] = el}
          />
        ))}
      </div>

      <div className="d-flex justify-content-center mt-10 ">
        <button className='btn btn-lg btn-primary me-2 mb-5 w-50' onClick={handleSubmit} disabled={loading}>
          {!loading && <span className='indicator-label'>SUBMIT</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default Setup2Fa;
