import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup'
import clsx from 'clsx'
import { resetPassword } from '../api';
import { useHistory } from 'react-router-dom';
import { KEY } from '../../../shared/constants/shared-contants';

const recaptchaRef = React.createRef<ReCAPTCHA>();

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Maximum 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Must contain at least one special character'),
  confirmPassword: Yup.string()
    .min(8, 'Maximum 8 characters')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Must contain at least one special character')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Password confirmation is required')
});

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setTimeout(() => {
        resetPassword(email, values.newPassword, values.confirmPassword, token, captchaToken)
          .then(() => {
            history.push('/auth/login');
          })
          .catch(({ response: { data: { message } } }) => {
            recaptchaRef.current?.reset();
            setStatus(message)
          })
          .finally(() => {
            setCaptchaToken(null);
            setSubmitting(false);
          })
      }, 1000)
    },
  });

  const onReCAPTCHAChange = async (captchaToken: string | null) => {
    if (captchaToken) {
      setCaptchaToken(captchaToken);
    }
  };

  const handleFormKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === KEY.enter) {
      event.preventDefault();
      if (formik.isValid && !formik.isSubmitting) {
        formik.handleSubmit();
      }
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setEmail(params.get('email') || '');
    setToken(params.get('token') || '');
  }, []);

  return <div>
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      onKeyDown={handleFormKeyDown}
      noValidate
      id='reset-password-form'>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-warning'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
      </div>

      <h6 className="mb-3">Password Guidelines</h6>
      <ul className='fs-7 text-muted'>
        <li>1 Uppercase letter</li>
        <li>1 Lowercase letter</li>
        <li>1 Special character</li>
        <li>1 Number</li>
        <li>Minimum of 8 characters</li>
      </ul>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
        <input
          placeholder='New Password'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
          type='password'
          name='newPassword'
          autoComplete='off'
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.newPassword}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Confirm Password</label>
        <input
          placeholder='Confirm Password'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
          type='password'
          name='confirmPassword'
          autoComplete='off'
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.confirmPassword}</span>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LcODrgpAAAAADg3cCPtrUHNltj3NjDTMl74sJXb"
          onChange={onReCAPTCHAChange}
        />
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button
          type='submit'
          id='kt_reset_password_submit'
          className='btn btn-lg btn-primary me-2 mb-5 w-50'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!formik.isSubmitting && <span className='indicator-label'>SAVE PASSWORD</span>}
          {formik.isSubmitting && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  </div>
}

export { ResetPassword} ;
