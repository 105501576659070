import { PriceOverrideData, DefaultCurrency, FreightTaxCode, SupplierCountry, SupplierDesignation, SupplierDetails, SupplierDetailsFormData, SupplierGridSetup, SupplierSalutation, SupplierState, SupplierStatus, SupplierTaxCode, SupplierType, PriceOverrrideGridSetup, PriceOverrideFormData } from "../models/supplier-models";

export const INITIAL_GRID_SETUP: SupplierGridSetup = {
  sort: [{ field: '', dir: 'asc' }],
  skip: 0,
  take: 10,
  filter: {
    logic: 'and',
    filters: [],
  },
}

export const INITIAL_PRICEOVERRIDE_GRID_SETUP: PriceOverrrideGridSetup = {
  sort: [{ field: '', dir: 'asc' }],
  skip: 0,
  take: 10,
  filter: {
    logic: 'and',
    filters: [],
  },
}

export const PRICEOVERRIDE_DATA_DEFAULT: PriceOverrideData = {
  id: 0,
  startDate: new Date(),
  endDate: new Date(),
  productId: 0,
  price: 0,
}

export const PRICEOVERRIDE_FORM_DEFAULT: PriceOverrideFormData = {
  ...PRICEOVERRIDE_DATA_DEFAULT,
  selectedProduct: { value: 0, label: '' },
}

export enum MapType {
  All = 0,
  NotMapped = 1,
};

export const SUPPLIER_DETAILS_DEFAULT: SupplierDetails = {
  id: 0,
  name: '',
  abn: '',
  status: SupplierStatus.Pending,
  type: SupplierType.Door,
  designation: SupplierDesignation.Company,
  scrapITCode: '',
  myobid: '',

  contactName: '',
  supplierSalutation: '',
  phone: '',
  website: '',
  email: '',

  address1: '',
  address2: '',
  suburbOrCity: '',
  postCode: '',
  state: SupplierState.QLD,
  country: SupplierCountry.Australia,

  taxCode: SupplierTaxCode.UseSupplierTaxCode,
  freightTaxCode: FreightTaxCode.UseSupplierTaxCode,
  taxCodeId: 0,
  freightTaxCodeId: 0,
  defaultCurrency: DefaultCurrency.AUD,
  bsb: '',
  account: '',
  accountName: '',
  remittanceEmail: '',

  supplierId: 0
};

export const SUPPLIER_DETAILS_FORM_DATA_DEFAULT: SupplierDetailsFormData = {
  ...SUPPLIER_DETAILS_DEFAULT,
  acceptTerms: false
}