import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { Logout, AuthPage } from '../modules/auth'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { RootState } from '../../setup'
import { MasterInit } from '../../_metronic/layout/MasterInit'
import CreateSupplierPage from '../modules/pages/suppliers/components/CreateSupplierPage'
import ThankYouPage from '../modules/pages/suppliers/components/ThankYouPage'

const Routes: FC = () => {
  const user = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any;

  return (
    <>
      <Switch>
        <Route path='/supplier-registration' component={CreateSupplierPage} />
        <Route path='/thank-you' component={ThankYouPage} />

        {!user ? (
          <>
            <Route>
              <AuthPage />
            </Route>
            <Redirect to='/auth/login' />
          </>
        ) : (
          <>
            <Redirect from='/auth' to='/' />
            <Route path='/logout' component={Logout} />
            <Route path='/error' component={ErrorsPage} />
            <MasterLayout>
              <PrivateRoutes activePages={user.activePages} />
            </MasterLayout>
          </>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export { Routes }
