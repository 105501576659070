import axios from 'axios'
import {ReCaptchaResponse, TenantFeatureList, UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Authenticate/verify-token`
export const LOGIN_URL = `${API_URL}/Authenticate/login`
export const LOGIN_2FA_URL = `${API_URL}/Authenticate/login-2FA`
export const REGISTER_URL = `${API_URL}/Authenticate/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/Authenticate/forgot-password`

export const FEATURES_URL = `${API_URL}/Feature`
export const CAPTCHA_URL = `${API_URL}/Captcha`

// Server should return AuthModel
export function login(email: string, password: string, captchaToken: string | null) {
  return axios.post(LOGIN_URL, {
    email,
    password,
    captchaToken
  })
}

export function login2Fa(email: string, code: string) {
  return axios.post(LOGIN_2FA_URL, {
    email,
    code,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    apiToken: token,
  })
}

export function getFeaturesPerTenant() {
  return axios.get<TenantFeatureList>(`${FEATURES_URL}/FeaturesPerTenant`)
}

export function verifyReCaptcha(token: string) {
  return axios.post<ReCaptchaResponse>(`${CAPTCHA_URL}/Verify-Recaptcha`, {
    token
  })
}