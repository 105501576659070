import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { LoginPage } from './components/LoginPage';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { ResetPassword } from './components/ResetPassword';
import { Login2Fa } from './components/Login2Fa';
import { AuthContextProvider } from './context/AuthContext';
import Setup2Fa from './components/Setup2Fa';
import Option2Fa from './components/Option2Fa';

export function AuthPage() {

  const history = useHistory();

  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <div className='container-fluid h-100 p-0'>
      <div className='row h-100 m-0'>
        <div className='col-md-4 d-flex flex-column align-items-center justify-content-center'>
          <div className='w-lg-500px p-10 p-lg-15 mx-auto' style={{ maxWidth: '100%' }}>
            <div className='text-center mb-12'>
              <a href='#'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/tall-ingots-logo.png')} className='h-45px' />
              </a>
            </div>
            <AuthContextProvider>
              <Switch>
                <Route path='/auth/login' exact component={LoginPage} />
                <Route path='/auth/login-2fa' render={() => <Login2Fa cancelHandler={() => { history.push('/auth/login') }} />} />
                <Route path='/auth/setup-2fa' exact component={Setup2Fa} />
                <Route path='/auth/option-2fa' exact component={Option2Fa} />
                <Route path='/auth/reset-password' exact component={ResetPassword} />
                <Route path='/auth/registration' exact component={Registration} />
                <Route path='/auth/forgot-password' exact component={ForgotPassword} />
                <Redirect from='/auth' exact to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </AuthContextProvider>
          </div>
        </div>

        <div className='col-md-8 d-none d-md-flex justify-content-center align-items-center bgi-position-y-center position-x-center bgi-size-contain bgi-attachment-fixed'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/tims-banner-auth.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }}>
          <div className='text-center'>
            <h1 className='text-white mb-10' style={{ fontSize: '4.875rem' }}>
              WE PAY TOP DOLLAR
              FOR YOUR SCRAP METAL.
            </h1>
            <h3 className='text-white'>
              No need to continue the search for "scrap metal recycling near me" - at Tall Ingot, we want what you have!
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
