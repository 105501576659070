import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as auth from '../redux/AuthRedux'
import { updateTwoFactorAuthentication } from '../api';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Option2Fa: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [notNowLoading, setNotNowLoading] = useState<boolean>(false);
  const [setup2Fa, setSetup2Fa] = useState<boolean>(false);
  const history = useHistory();
  const { email, token} = useContext(AuthContext);

  useEffect(() => {
    if (!email || !token) {
      history.push('/auth/login');
    }
  }, [history, email, token])

  const handleAllow2Fa = async () => {
    setLoading(true);
    setTimeout(() => {
      setSetup2Fa(true)
    }, 1000)
  };

  const handle2FaNotNow = async () => {
    setNotNowLoading(true);
    setTimeout(async () => {
      dispatch(auth.actions.login(token));
      await updateTwoFactorAuthentication(email, false);
    }, 1000);
  };

  if (setup2Fa) {
    history.push('/auth/setup-2fa');
  }

  return (
    <div className="text-center">
      <h4 className='mb-10'>Two Factor Authentication</h4>
      <span>Your account is not setup for  Two Factor Authentication. Would you like to setup now?</span>
      <div className="d-flex justify-content-center mt-10 ">
        <button className='btn btn-lg btn-primary me-2 mb-5 w-50' onClick={handleAllow2Fa} disabled={loading || notNowLoading} >
          {!loading && <span className='indicator-label'>YES</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button className='btn btn-lg btn-secondary mb-5 w-50' onClick={handle2FaNotNow} disabled={loading || notNowLoading} >
          {!notNowLoading && <span className='indicator-label'>NOT NOW</span>}
          {notNowLoading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default Option2Fa;