import { Modal } from 'react-bootstrap-v5'
import * as Yup from 'yup'
import { useFormik } from 'formik';
import clsx from 'clsx';
import React, { useState } from 'react';
import { changePassword } from '../api/index';
import { useDispatch } from 'react-redux';
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

interface IProps {
  show: boolean
  handleClose: () => void
}

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Maximum 8 characters')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Must contain at least one special character'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Maximum 8 characters')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Must contain at least one special character'),
  confirmPassword: Yup.string()
    .min(8, 'Maximum 8 characters')
    .matches(/[0-9]/, 'Must contain at least one number')
    .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Must contain at least one lowercase letter')
    .matches(/[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/, 'Must contain at least one special character')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Password confirmation is required')
});

const initialValues = {
  newPassword: '',
  confirmPassword: '',
  password: ''
}

const ChangePasswordModal: React.FC<IProps> = ({ show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch()

  const handleSave = () => {
    setLoading(true);
    setTimeout(() => {
      const { password, newPassword, confirmPassword } = formik.values;
      changePassword(password, newPassword, confirmPassword)
        .then(() => {
          handleBeforeClose();
        })
        .catch(({ response: { data: { message } } }) => {
          setError(message);
        })
        .finally(() => {
          setLoading(false);
        })

    }, 1000)
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
    },
  });

  const handleBeforeClose = () => {
    formik.setValues(initialValues);
    formik.setTouched({ password: false, newPassword: false, confirmPassword: false });
    dispatch(auth.actions.logout())
    setError('');
    handleClose();
  }

  const handleFormKeyDown = (event: any) => {
    if (event.key === 'Enter' && formik.isValid) {
      event.preventDefault();
      if (formik.isValid && !formik.isSubmitting) {
        formik.handleSubmit();
      } 
    }
  }

  return (
    <Modal show={show} onHide={handleBeforeClose}
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <form
            className='form w-75'
            onSubmit={formik.handleSubmit}
            onKeyDown={handleFormKeyDown}
            noValidate
            id='reset-password-form'>

            {error ? (
              <div className='mb-lg-15 alert alert-warning'>
                <div className='alert-text font-weight-bold'>{error}</div>
              </div>
            ) : (
              <></>
            )}

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
              <input
                placeholder='Password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.password && formik.errors.password },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                type='password'
                name='password'
                autoComplete='off'
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container text-muted mt-1'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
              <input
                placeholder='New Password'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
                type='password'
                name='newPassword'
                autoComplete='off'
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='fv-plugins-message-container  text-muted mt-1'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              )}
            </div>

            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-dark'>Confirm Password</label>
              <input
                placeholder='Confirm Password'
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                  {
                    'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                  }
                )}
                type='password'
                name='confirmPassword'
                autoComplete='off'
              />
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container  text-muted mt-1'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
         <button type='button' className='btn btn-outline-secondary' 
          onClick={handleBeforeClose}>Cancel</button>
        <button
          type='button'
          className='btn btn-primary'
          onClick={handleSave}
          disabled={loading || !formik.isValid} >
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Saving...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
      </Modal.Footer>
    </Modal>
  )
}

export { ChangePasswordModal }
