import axios from 'axios';

export const AUTHENTICATE_URL = `${process.env.REACT_APP_API_URL}/Authenticate`;

export const updateTwoFactorAuthentication = (email: string, enabled: boolean) => {
  axios.patch(`${AUTHENTICATE_URL}/update-2FA`, { enabled, email });
};

export const setupAuthenticator = (email: string) => {
  return axios.post(`${AUTHENTICATE_URL}/setup-authenticator`, { email });
};

export const verify2FaCode = (email: string, code: string) => {
  return axios.post(`${AUTHENTICATE_URL}/verity-2fa-code`, { email, code });
};

export const resetPassword = (email: string, newPassword: string, confirmPassword: string, token: string, captchaToken: string | null) => {
  return axios.post(`${AUTHENTICATE_URL}/reset-password`, { email, newPassword, confirmPassword, token, captchaToken });
}