import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useHistory } from 'react-router-dom'

const ThankYouPage: React.FC = () => {
  const history = useHistory()
  const redirectToDashboard = () => {
    history.push('/')
  }
  return (
    <div className='d-flex flex-column flex-root'>
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')` }}
      >
        <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-20'>
          <a href='/dashboard' className='mb-10 pt-lg-20'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/tall-ingots-logo.png')}
              className='h-50px mb-5'
            />
          </a>
          <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Thank You</h1>

          <div className='fw-bold fs-3 text-gray-400 mb-15' style={{ maxWidth: '800px', margin: '0 auto' }}>
            Thank you for your new supplier form submission, a Tall Ingots team member will review your submission and contact you regarding further information if needed.
          </div>


          <div className='pt-lg-10 mb-10'>
            <div className='text-center'>
              <a onClick={redirectToDashboard} className='btn btn-lg btn-primary fw-bolder'>
                Go to homepage
              </a>
            </div>
          </div>
          <div
            className='
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          min-h-100px min-h-lg-350px
        '
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ThankYouPage
