/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { PAGE_PERMISSION } from '../../../../app/modules/common/constant/roles-constant'

export function AsideMenuMain() {

  const intl = useIntl()

  return (
    <>
      <div className='mb-3'>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/tims/menu-dashboard.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
          pagePermission={ PAGE_PERMISSION.Dashboard}
        />
      </div>

      <AsideMenuItem
        to='/sales'
        title="Sales"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-sales.svg'
        pagePermission={ PAGE_PERMISSION.Sales }
      />

      <AsideMenuItem
        to='/quotes'
        title="Quotes"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-qoutes.svg'
        pagePermission={ PAGE_PERMISSION.Quotes }
      />

      <AsideMenuItem
        to='/float-and-cash'
        title="Float & Cash"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-float-and-cash.svg'
        pagePermission={ PAGE_PERMISSION.FloatAndCash }
      />

      <AsideMenuItem
        to='/price-list'
        title="Price List"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-price-list.svg'
        pagePermission={ PAGE_PERMISSION.PriceList }
      />

      <AsideMenuItem
        to='/suppliers'
        title="Suppliers"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-suppliers.svg'
        pagePermission={ PAGE_PERMISSION.Suppliers }
      />

      <AsideMenuItem
        to='/products'
        title="Products"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-product.svg'
        pagePermission={ PAGE_PERMISSION.Products }
      />

      <AsideMenuItem
        to='/reports'
        title="Reports"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-reports.svg'
        pagePermission={ PAGE_PERMISSION.Reports }
      />

      <AsideMenuItemWithSub to='/administration'
        title="Administration"
        fontIcon='bi-chat-left'
        icon='/media/icons/tims/menu-administration.svg'
        pagePermissions={[
          PAGE_PERMISSION.Admin_Audit,
          PAGE_PERMISSION.Admin_MYOB,
          PAGE_PERMISSION.Admin_RolesAndPermissions,
          PAGE_PERMISSION.Admin_Settings,
          PAGE_PERMISSION.Admin_UserManagement]}
        >

        <AsideMenuItem
          to='/user-management'
          title="User Management"
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-users.svg'
          pagePermission={ PAGE_PERMISSION.Admin_UserManagement }
        />

        <AsideMenuItem
          to='/role'
          title="Roles and permissions"
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-roles.svg'
          pagePermission={ PAGE_PERMISSION.Admin_RolesAndPermissions }
        />

        <AsideMenuItem
          to='/settings'
          title="Settings"
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-settings.svg'
          pagePermission={ PAGE_PERMISSION.Admin_Settings }
        />

        <AsideMenuItem
          to='/myob'
          title="MYOB"
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-accounting.svg'
          pagePermission={ PAGE_PERMISSION.Admin_MYOB }
        />

        <AsideMenuItem
          to='/audit'
          title="Audit"
          fontIcon='bi-chat-left'
          icon='/media/icons/tims/menu-audit.svg'
          pagePermission={ PAGE_PERMISSION.Admin_Audit }
        />

      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
